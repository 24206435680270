<template>
    <base-card class="pa-4">
        <v-card-title class="pa-1 pl-3 font-weight-bold">Advance Filter</v-card-title>
        <v-row class="ma-0 pa-0">
            <v-col cols="3">
                <v-switch dense label="Active / Deactive" color="info" hide-details
                    v-model="searchModel.status" :false-value="0" :true-value="1"></v-switch>
            </v-col>
            <v-col cols="8">
                <v-btn type="button" class="ma-2" dark color="info" @click="resetModel()">
                    <v-icon left>mdi-refresh</v-icon>
                    Reset
                </v-btn>
                <v-btn type="button" class="ma-2" dark color="primary" @click="searchFilter">
                    <v-icon small left>mdi-account-search-outline</v-icon>
                    Search
                </v-btn>
            </v-col>
        </v-row>
    </base-card>
</template>

<script>
export default {
    props: ['clearAdvFilter'],
    emits: ['reset', 'search'],
    data() {
        return {
            searchModel: {
                status: 0
            },
        }
    },
    watch: {
        'clearAdvFilter': function(newVal) {
            if (newVal) {
                this.resetModel('clear')
            }
        }
    },
    methods: {
        resetModel(clearAdv) {
            this.searchModel.status = 0;
            if (!clearAdv) {
                this.$emit('reset');
            }
        },
        searchFilter() {
            this.$emit('search', this.searchModel);
        },
        clearDate(evt){
            if(!evt) this.searchModel.status = 0;
        }
    }
}
</script>